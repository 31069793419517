<template>
  <div>
    <b-overlay :show="false" rounded="sm">
    <b-card>
      <b-form class="mt-2" ref="formReport">       
        <input type="hidden" name="task_id" v-model="task_id">
        <!-- form -->
        <b-row>
          <!-- <b-col sm="12">
            <b-form-group label="TITULO DE PRESPUESTO" label-for="description">
              <b-form-textarea name="description" required />
            </b-form-group>
          </b-col> -->
            <!--TODO añadir más campos como tipo_iva, clausula y metodo de pago -->
            <b-col sm="4">           
              <b-form-group label="Cláusula" label-for="clause">
                <v-select
                  :options="clauses"                
                  placeholder="Seleccione cláusula"
                  label="title"
                  :getOptionKey="getOptionKey"
                  name="clause_id"
                  v-model="clause_id"
                />           
              </b-form-group>
            </b-col>
          <b-col sm="4">           
            <b-form-group label="Notas internas" label-for="internal_note">
              <b-form-textarea name="internal_note" v-model="internal_note" />    
            </b-form-group>
          </b-col>
          <b-col sm="4">           
            <b-form-group label="Mensaje a mostrar (observaciones)" label-for="message">
              <b-form-textarea name="message" v-model="message" />    
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-button variant="primary" class="mt-2 mr-1" type="button" @click="saveForm">
              Crear parte de trabajo
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
    </b-overlay>
  </div>
</template>

<script>
  import { BFormTextarea, BButton, BForm, BOverlay,BCardHeader, BCardBody, BFormGroup, BFormInput, BRow, BCol,  BCard,BCardText} from 'bootstrap-vue';
  import vSelect from 'vue-select';
  export default {
    components : { BFormTextarea, vSelect, BButton, BForm, BOverlay,BCardHeader, BCardBody, BFormGroup, BFormInput, BRow, BCol, BCard, BCardText,},
    data() {
      return {
        task_id : this.$route.params.task,
        budget_date : new Date().toISOString().slice(0,10),
        internal_note: null,
        message: null,
        clauses: [],
        clause_id: null,
      }
    },
    created () {
      this.$http.get("/api/getClauses").then((response) => {
        this.clauses = response.data;       
      })
    },
    methods: {
      getOptionKey(option) {
        return option ? option.id : null;
      },
      saveForm : async function (e) {
        this.show = true;
        var formData = new FormData(this.$refs.formReport);
        await this.$http({
          method: "post",
          url: "/api/insertReport",
          data: formData,
        }).then(response => { 
          console.log(response.data);
          let id = response.data
          this.show = false;
          this.$router.push("/budget/"+id);
        })
          .catch(response => { window.console.log(response) });
      }
    }
  }
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>>